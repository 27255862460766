<template lang="pug">
div(class='ModalContainer', @click.self='modalHide')
	div(v-if='!modalState.type || modalState.type === Modal.Type.FEEDBACK', class='Modal')
		div(class='Modal--Close', @click='modalHide')
		div(class='Modal--Headers')
			div(class='Title') {{ modalState.header }}
			div(class='Description') {{ modalState.description }}
		div(class='Modal--Form')
			UiForm(
				:items='modalState.form',
				:source='modalState.header',
				:subject='modalState.subject',
				:target='modalState.target',
				ref='form'
			)
		div(class='Modal--Submit')
			div(class='Privacy')
				| Нажимая кнопку “Отправить” вы соглашаетесь с
				|
				NuxtLink(to='/privacy') политикой конфиденциальности
			UiButton(variant='primary', theme='filled', size='xl', rounded, @click='modalSubmit') Отправить
	div(v-if='modalState.type === Modal.Type.IFRAME', class='Modal Iframe')
		div(class='Modal--Close', @click='modalHide')
		div(class='Modal--Headers')
			div(class='Title') {{ modalState.header }}
		div(class='Modal--Iframe', v-html='modalState.iframe')
</template>

<script lang="ts" setup>
import { UiForm } from '#components';
import { Modal } from '~/models/modal';

const { modalState, modalHide } = useModal();

const form = ref<InstanceType<typeof UiForm> | null>(null);

const modalSubmit = () => {
	if (form.value) {
		form.value.submitForm();
	}
};
</script>

<style lang="less" scoped>
.ModalContainer {
	padding: 1.25rem;
	.backdrop(blur; 0.3125rem);
	.fixed(99999; auto; 0 0 0 0);
	.box(grid; auto; auto; fade(@ColorBase, 75%); 0.5rem; 1fr auto 1fr; auto; start; center);

	& .Modal {
		padding: 1.5rem;
		min-height: 30rem;
		max-width: 27rem;
		.grid(2 3; 1 span);
		.box(grid; 100%; auto; @ColorWhite; 1.25rem; 3.125rem auto 1fr auto; auto; start; start);
		.border-radius(@BorderRadiusMedium);

		&.Iframe {
			max-width: 100%;
			width: auto;
		}

		&--Close {
			.relative(0);
			.cursor-pointer();
			.grid(1 span; 1 span; center; end);
			.box(flex; 3.125rem; 3.125rem; fade(@ColorBase, 5%); center; center; center);
			.border-radius(@BorderRadiusFull);
			.transition(all; 250ms; 'sine');
			&:before,
			&:after {
				content: '';
				.absolute(0);
				.box(1.25rem; 0.125rem; @ColorBase);
				.border-radius(@BorderRadiusFull);
				.transition(all; 250ms; 'sine');
			}
			&:before {
				.rotate(-135deg);
				.translateY(0);
			}
			&:after {
				.rotate(135deg);
				.translateY(0);
			}
			&:hover {
				&:before {
					.background(@ColorPrimary);
				}
				&:after {
					.background(@ColorPrimary);
				}
			}
		}

		&--Headers {
			.grid(1 span);
			.box(grid; 100%; auto; none; 0.5rem; auto; 1fr; center; center);
			& > .Title {
				.grid(1 span);
				.text(@ColorBase; 1.125em 2rem @bold; center; none);
			}
			& > .Description {
				padding: 0 1.25rem;
				.grid(1 span);
				.text(@ColorBase; 1.25em 1.125rem @regular; center; none);
			}
		}

		&--Form {
			.grid(1 span);
			.box(grid; 100%; auto; none; 0.5rem; auto; auto; center; center);
		}

		&--Iframe {
			.grid(1 span);
			.box(grid; 100%; auto; none; 0.5rem; auto; auto; center; center);
			& p {
				padding: 0;
				margin: 0;
			}
		}

		&--Submit {
			.grid(1 span);
			.box(grid; 100%; auto; none; 1.25rem; auto; 1fr; center; center);
			& > .Privacy {
				.grid(1 span);
				.text(@ColorBase; 1.5em 0.875rem @regular);
				& > a {
					.text(@ColorPrimary);
				}
			}
			& > .UiButton {
				.box(100%);
			}
		}
	}
}
</style>
