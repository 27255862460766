<template lang="pug">
Header
Main
	NuxtPage
Footer
transition(name='fade')
	Modal(v-if='modalShown')
transition(name='fade')
	Cookie(v-if='!acceptedCookie')
</template>

<script lang="ts" setup>
const { modalShown } = useModal();
const { acceptedCookie, fetchAcceptedCookie } = useCookieAccept();

fetchAcceptedCookie();
</script>
