<template lang="pug">
div(class='Cookie')
	div(class='Cookie--Text')
		span {{ cookieText }}
	div(class='Cookie--Button')
		UiButton(variant='secondary', theme='outline', size='sm', rounded, to='/privacy') Подробнее
		UiButton(variant='primary', theme='filled', size='sm', rounded, @click='acceptCookies') {{ acceptCookiesText }}
</template>

<script lang="ts" setup>
const { cookieText, acceptCookiesText, acceptCookies } = useCookieAccept();
</script>

<style lang="less" scoped>
.Cookie {
	padding: 2rem;
	border: 0.0625rem solid @ColorPrimary;
	.fixed(100; none; 4rem 1rem none 1rem);
	.box(grid; auto; auto; fade(@ColorWhite, 75%); 1rem; auto; 1fr; center; start);
	.border-radius(@BorderRadius);
	.transition(all; 250ms; 'sine');
	.backdrop('blur', 0.5rem);

	&--Text {
		.grid(1 span);
		.text(@ColorBase; 1.25em 1.125rem @semibold);
	}

	&--Button {
		.grid(1 span);
		.box(grid; auto; auto; none; 1rem; 1fr; auto; center; start; column);
		& > * {
			.grid(1 span);
		}
	}

	@media all and (min-width: @tablet) {
		max-width: 20rem;
		.fixed(100; none; unset 1rem 2rem 1rem);
	}
	@media all and (min-width: 1400px) {
		.calc(left; ~'50% - 42.5rem');
	}
}
</style>
