export const useCookieAcceptState = () => ({
	acceptedCookie: useState<number>('acceptedCookie', () => 0),
});

export const useCookieAccept = () => {
	const cookieAccepted = useCookie('cookieAccepted');
	const { acceptedCookie } = useCookieAcceptState();

	const cookieText = 'Мы используем Cookie';
	const acceptCookiesText = 'Принять';

	/**
	 * Fetch accepted cookie
	 */
	const fetchAcceptedCookie = async () => {
		if (acceptedCookie.value) return;

		acceptedCookie.value = String(cookieAccepted.value) === '1' ? 1 : 0;
	};

	/**
	 * Accept cookies
	 */
	const acceptCookies = async () => {
		cookieAccepted.value = '1';
		acceptedCookie.value = 1;
	};

	return {
		cookieText,
		acceptCookiesText,
		acceptedCookie,
		acceptCookies,
		fetchAcceptedCookie,
	};
};
